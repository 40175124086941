import gql from 'graphql-tag'

export const GetAdminShops = gql`
query ($currentPage: Int, $perPage: Int, $orderBy: String, $search: String) {
  adminShops (currentPage: $currentPage, perPage: $perPage, orderBy: $orderBy, search: $search) {
    items {
      id
      code
      name
      prefectureName
      businessContents
      logoImageSet {
        xsSqImage
      }
      ogImageSet {
        mdImage
        ogpImage
      }
    }
    pageInfo {
      currentPage
      totalCount
      perPage
      orderBy
    }
  }
}`

export const GetAdminShopLaw = gql`
query ($adminShopId: ID, $adminShopCode: String) {
  adminShop (adminShopId: $adminShopId, adminShopCode: $adminShopCode) {
    id
    code
    name
    email
    companyName
    representativeName
    isIndividual
    zipCode
    address
    isShowAddress
    isShowTel
    tel
    isShowFax
    fax
    isCodAvailable
    codFees{
      id
      fee
      maxPrice
    }
    isBankTransferAvailable
    isDeliveryPrefecturePrice
    isDeliveryCoolAddPrice
    isCreditCardAvailable
    isAmazonPayAvailable
    deliveryCoolAddPrice
    deliveryCoolAddPriceInTax
    isDeliveryFreeShippingOn
    freeShippingPrice
    storageTypeNum
    lawOtherFee
    lawDeliveryTime
    lawPaymentTime
    lawPaymentMethod
    lawReturn
    lawReturnShipping
    lawLicense
    creditBrandsAcceptedList
    displayBankName
    displayBankBranchName
    displayBankType
    displayBankAccountNumber
    displayBankPersonName
    yuutyoBankBranchName
    yuutyoBankBranchNumber
    yuutyoBankType
    yuutyoBankAccountNumber
    yuutyoBankKigouNumber
    yuutyoBankPersonName
    invoiceType
    invoiceNo
    delivery {
      id
      isSysytemShippingAgreements
      maxShippingPreparerPeriod
    }
    masterShippingPrices {
      id
      priceInTax
      toShippingRegion {
        id
        name
        prefectureNameList
      }
    }
    shopShippingPriceList {
      id
      priceInTax
      addPriceInTax
      toShippingRegion {
        id
        name
        prefectureNameList
      }
    }
  }
}`


export const GetAdminShop = gql`
query ($adminShopId: ID, $adminShopCode: String) {
  adminShop (adminShopId: $adminShopId, adminShopCode: $adminShopCode) {
    id
    code
    name
    prefectureName
    businessContents
    description
    isDeliveryFreeShippingOn
    isDeliveryFixedPrice
    isDeliveryPrefecturePrice
    isDeliveryCoolAddPrice
    deliveryCoolAddPriceInTax
    freeShippingPrice
    isDeliveryPrefecturePrice
    deliveryFixedShippingPriceInTax
    deliveryMinimumPrefecturePriceInTax
    addFeePrefectureList
    isDeliveryFixedPrice
    lawDeliveryTime
    lawCancel
    lawReturn
    lawReturnShipping
    isShowAddress
    zipCode
    address
    isShowTel
    tel
    specifiedDeliveryDateTerm
    isCreditCardAvailable
    creditBrandsAcceptedList
    isCodAvailable
    displayBankName
    displayBankBranchName
    displayBankType
    displayBankAccountNumber
    displayBankPersonName
    yuutyoBankBranchName
    yuutyoBankBranchNumber
    yuutyoBankType
    yuutyoBankAccountNumber
    yuutyoBankKigouNumber
    yuutyoBankPersonName
    qaSupportStatus
    minCodFee {
      id
      feeInTax
    }
    isConvenienceAvailable
    isBankTransferAvailable
    logoImageSet {
      mdSqImage
    }
    ogImageSet {
      lgImage
    }
    profileImageSet {
      mdSqImage
    }
    shopShippingPriceList {
      toShippingRegion {
        name
        prefectureNames
      }
      priceInTax
      addPriceInTax
    }
    codFees {
      maxPrice
      feeInTax
    }
  }
}`

export const GetAdminShopForContact = gql`
query ($adminShopId: ID, $adminShopCode: String) {
  adminShop (adminShopId: $adminShopId, adminShopCode: $adminShopCode) {
    code
    name
    email
    isSystemAdmin
    hasDeliveryTimerMemo
    delivery {
      timerMemo
    }
  }
}`
