<template lang="pug">
  section.section-item-description
    ContainerFrame
      .desc-wrapper
        nav.table-of-contents
          h2 商品について
          ul
            li(v-if="showFoodLabel" @click="$scrollTo('#outline-food-labels', {offset: -110})")
              p 食品表示・生産表示
              ArrowSvg.icon
            li(v-for="item in itemGroupDescriptions" v-if="item.contentType === 'title_content'" @click="$scrollTo(`#outline${item.id}`, {offset: -110})" :key="item.id")
              p {{item.title}}
              ArrowSvg.icon

        ReadMore.mt-xlg(v-if="showFoodLabel" :minHeight="600" more-text="食品表示・生産表示をもっと見る")
          TableBase.food-labels(:is-bordered="true" id="outline-food-labels")
            tbody
              tr(v-if="itemGroup.rawMaterials")
                th.heading 原材料
                td.description
                  VueMarkdown.markdown(:source="itemGroup.rawMaterials" :linkify="false")
              tr(v-if="hasInnerCapacity")
                th.heading 内容量
                td.description
                  p(v-for="item in innerCapacityItems") {{item.size}} : {{item.innerCapacity}}
              tr(v-if="itemGroup.bestBefore")
                th.heading 賞味期限
                td.description {{itemGroup.bestBefore}}
              tr(v-if="itemGroup.preservationMethod")
                th.heading 保存方法
                td.description {{itemGroup.preservationMethod}}
              tr(v-if="itemGroup.publishedNotice && itemGroup.notice")
                th.heading notice
                td.description {{itemGroup.notice}}

        .descriptionBlock(v-for="item in itemGroupDescriptions" :id="`outline${item.id}`")
          .title.mt-xlg(v-if="item.contentType === 'title_content'")
            HeadingArticle {{item.title}}
          .desc.mt-md(v-else-if="item.contentType === 'description_content'")
            ItemMarkdown(:description="item.description")
          .image.mt-md(v-else-if="item.contentType === 'image_content'")
            ImgResponsive(:src="item.imageSet.mdImage")
          .item.mt-md(v-else-if="item.contentType === 'item_group_content'")
            ItemLink(:item-group="item.descriptionItemGroup" :adminShop="itemGroup.adminShop")
          .item.mt-md(v-else-if="item.contentType === 'page_content'")
            PageLink(:page="item.page" :item-group="itemGroup")


        HeadingArticle.mt-md 私たちの紹介
        SectionShopProfile.mt-sm(:adminShop="itemGroup.adminShop")
</template>

<script>
  import VueMarkdown from 'vue-markdown'
  import ContainerFrame from '../container/ContainerFrame'
  import HeadingArticle from '../typography/HeadingArticle'
  import ItemMarkdown from '../item/ItemMarkdown'
  import ImgResponsive from '../../image/ImgResponsive'
  import PageLink from '../item/PageLink'
  import ItemLink from '../item/ItemLinkForApi'
  import ReadMore from '../transition/ReadMore'
  import TableBase from '../table/TableBase'
  import SectionShopProfile from "./SectionShopProfile";
  import ArrowSvg from '../../svg/ArrowSvg'

  export default {
    name: 'SectionItemDescription',
    components: {
      SectionShopProfile,
      VueMarkdown,
      ContainerFrame,
      HeadingArticle,
      ItemMarkdown,
      ImgResponsive,
      PageLink,
      ItemLink,
      ReadMore,
      TableBase,
      ArrowSvg,
    },
    props: {
      itemGroup: {
        type: Object,
        required: true,
        default: () => {}
      }
    },
    computed: {
      itemGroupDescriptions() {
        return this.itemGroup.publishedItemGroupDescriptions || []
      },
      hasInnerCapacity() {
        return this.innerCapacityItems.length > 0
      },
      innerCapacityItems() {
        return this.itemGroup.openItems.filter(item => !!item.innerCapacity)
      },
      currentUser () {
        return this.$store.getters.currentUser;
      },
      showFoodLabel() {
        return this.itemGroup.rawMaterials
            || this.hasInnerCapacity
            || this.itemGroup.bestBefore
            || this.itemGroup.preservationMethod
            || (this.itemGroup.publishedNotice && this.itemGroup.notice)
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .section-item-description {
    > .container-frame {
      > .desc-wrapper {
        max-width: 700px;
        margin: 0 auto;
        > .table-of-contents {
          margin: 16px auto 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 16px;
          align-self: stretch;
          width: 100%;
          color: $black-400;
          > h2{
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            font-family: $font-family-serif;
          }
          > ul {
            width: 100%;
            > li {
              display: flex;
              padding: 12px 0px;
              justify-content: space-between;
              align-items: center;
              align-self: stretch;
              border-bottom: 1px solid $olive-300;
              cursor: pointer;
              > p {
                color: $black-400;
                text-decoration: none;
                font-size: 14px;
              }
            }
          }
        }
      }

      .food-labels {
        .heading {
          width: 90px;
          text-align: left;
        }
        .heading, .description {
          font-family: $font-family-sans;
          font-weight: $font-weight-regular;
          font-style: $font-style-not-italic;
          line-height: $line-height-normal;
          color: $black-400;
        }
      }

      .shop-profile {
        display: flex;
        justify-content: space-between;
        $col: 100% / 12;

        > .image {
          width: calc(#{$col * 2} - 7.5px);
          > .img-responsive {
            border-radius: 50%;
            overflow: hidden;
          }
        }

        > .desc {
          width: calc(#{$col * 10} - 7.5px);

          > h1 {
            font-family: $font-family-serif;
            font-weight: $font-weight-regular;
            font-style: $font-style-not-italic;
            @include font-size(md);
            line-height: $line-height-normal;
            color: $black-400;
            @include margin-bottom(xxs);

            > span {
              @include margin-left(xs);
              @include font-size(xxs);
            }
          }

          > .description {
            white-space: pre-wrap;
            word-wrap: break-word;
            font-family: $font-family-sans;
            font-weight: $font-weight-regular;
            font-style: $font-style-not-italic;
            @include font-size(xs);
            line-height: $line-height-relaxed;
            color: $black-400;
          }
        }
      }
    }
  }
</style>

<style lang="scss">
  @import "../../../../../assets/stylesheets/v1/styles";

  .section-item-description {
    .food-labels {
      .markdown {
        p {
          font-family: $font-family-sans;
          font-weight: $font-weight-regular;
          font-style: $font-style-not-italic;
          @include font-size(xs);
          line-height: $line-height-normal;
          color: $black-400;
          @include margin-bottom(xxs);
        }
      }
    }
    .shop-profile {
      .markdown {
        p {
          font-family: $font-family-sans;
          font-weight: $font-weight-regular;
          font-style: $font-style-not-italic;
          @include font-size(xs);
          line-height: $line-height-normal;
          color: $black-400;
          @include margin-bottom(xxs);
        }
      }
    }
  }
</style>
