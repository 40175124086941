import { render, staticRenderFns } from "./FreeShipping.vue?vue&type=template&id=2ece82bf&scoped=true&lang=pug&"
import script from "./FreeShipping.vue?vue&type=script&lang=js&"
export * from "./FreeShipping.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ece82bf",
  null
  
)

export default component.exports