<template lang="pug">
  div
    .listvisual_shop(:style="`background-image: url(${titleBgImage});`")
      h2.line_height1_2
        span.font_xs {{adminShop.prefectureName}}
        br
        | {{adminShop.name}}
    h1.line_height1_2.text-center.mt2
      | {{adminShop.name}}の商品一覧

    ItemFilterBlock
      DropDown(@change="$_changeOrder" v-model="pageInfo.orderBy" :options="$_orderDropDownForShopOptions")
      .stock-checkbok
        FormCheckbox(@change="$_changeStockFilter" v-model="stockOnly") 販売中のみ表示
    ItemCardList
      .item.lg(v-for="(itemGroup, index) in itemGroups" :key="itemGroup.id")
        ItemCard(:itemGroup="itemGroup" eventCategory="商品一覧" pageContents="商品一覧")
    .row
      .col-xs-12(v-if="itemGroups.length === 0")
        .success-notice
          .text-center 商品がありません。
      .col-xs-12
        Pager(@change="$_changePage" :custom-page-info="$_customPageInfo")


    SectionItemArchive
</template>

<script>
  import {GetAdminShop} from "../../graphqls/AdminShops";
  import { GetItemGroupsForApi } from "../../graphqls/ItemGroups";

  import LogoYaneSvg from "../../components/svg/LogoYaneSvg";
  import ItemList from "../../components/list/ItemList";
  import GlobalMixin from "../../mixin/GlobalMixin";
  import Pager from "../../components/paginate/Pager";
  import DropDown from "../../components/v1/dropdown/DropDown";
  import ItemListMixin from "../../mixin/ItemListMixin";
  import ButtonMain from "../../components/button/ButtonMain";
  import ReviewItem from "./ReviewItem";
  import LazyImg from "../../components/image/LazyImg";
  import SectionItemArchive from "../../components/v1/section/SectionItemArchive";
  import ItemFilterBlock from "../../components/v1/item/ItemFilterBlock";
  import FormCheckbox from "../../components/v1/form/FormCheckbox";
  import ItemCardList from "../../components/v1/item/ItemCardList";
  import ItemCard from "../../components/v1/item/ItemCardForApi";

  export default {
    name: "shop-items-index",
    mixins: [GlobalMixin, ItemListMixin],
    components: {
      ItemCard,
      ItemCardList,
      FormCheckbox,
      ItemFilterBlock,
      SectionItemArchive,
      LazyImg,
      ReviewItem,
      ButtonMain,
      DropDown,
      Pager,
      ItemList,
      LogoYaneSvg
    },
    props: [],
    data() {
      return {
        adminShop: {
          name: "",
          ogImageSet: {
            lgImage: ''
          }
        },
        itemGroups: [],
        pageInfo: {
          currentPage:  Number(this.$route.params.page || 1),
          totalCount: 0,
          perPage: 48,
          orderBy: this.$route.query.order || 'recommend_shop'
        },
        stockOnly: this.$route.query?.stock === 'instock',
      }
    },
    computed: {
      titleBgImage() {
        return this.ogImage || ''
      },
      ogImage() {
        return this.adminShop.ogImageSet.lgImage
      },
      adminShopCode() {
        return this.$route.params.adminShopCode
      },
      stockFilter() {
        return this.stockOnly ? 'instock' : 'outstock'
      }
    },
    mounted() {
      this.fetchAdminShop()
      this.fetchItemGroups()
    },
    methods: {
      async fetchItemGroups() {
        this.loadingStart();
        const { data } = await this.$apollo
          .query({
            query: GetItemGroupsForApi,
            variables: {
              adminShopCode: this.adminShopCode,
              stockFilter: this.stockFilter,
              ...this.pageInfo
            },
            client: 'apiClient'
          })
          .catch(error => {
            this.loadingStop()
            return;
          });
        this.itemGroups = data.itemGroups.items;
        this.pageInfo = data.itemGroups.pageInfo
        this.$_gaSendViewItemList(this.itemGroups, `shop/${this.$route.params.tagCode}/items/`, this.pageInfo)
        this.loadingStop();
      },
      async fetchAdminShop() {
        const { data } = await this.$apollo
        .query({
          query: GetAdminShop,
          variables: {
            adminShopCode: this.adminShopCode,
          }
        })
        .catch(error => {
          return;
        });
        this.adminShop = data.adminShop;
      }
    }
  }
</script>

<style scoped lang="scss">
</style>
