<template lang="pug">
  section(v-if="showSectionTag").section-recommend#Tag
    HeadingGift.mb-sm
      h2 
        span.sub {{ giftPageBody.subTitle }}
        br 
        | {{ giftPageBody.title }}
    ItemCardList
      .item(v-for="(itemGroup, index) in itemGroups" :key="itemGroup.id")
        ItemCard(:itemGroup="itemGroup" :eventCategory="`${itemTag.name}タグ商品一覧`" :pageContents="`${itemTag.name}タグ商品一覧`")
    .text-center(v-if="giftPageBody.itemTagDisplayMaxCount")
      ButtonBase(:href="`/shops/items/tag/${tagCode}/?bbLink=/gifts/${giftPageCode}/&bbLabel=${giftPageName}特集を見る&bbAction=特集ページへ遷移&bbCategory=タグ商品一覧`" eventAction="商品一覧へ遷移" eventCategory="ギフトページ") もっと見る

</template>

<script>
  import HeadingGift from '../heading/HeadingGift'
  import ItemCard from '../item/ItemCardForApi'
  import ItemCardList from '../item/ItemCardList'
  import {GetItemGroupsForApi} from "../../../graphqls/ItemGroups";
  import ButtonBase from "../button/ButtonBase";

  export default {
    name: 'SectionTag',
    components: {
      ButtonBase,
      HeadingGift,
      ItemCard,
      ItemCardList
    },

    props: {
      giftPageBody: {
        type: Object,
        required: true,
        default: function () {
          return {}
        }
      },
      giftPageName: {
        type: String,
        default: ''
      },
      giftPageCode: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        itemTag: {
          name: "",
          ogImageSet: {
            ogpImage: ''
          }
        },
        itemGroups: [],
        stockOnly: !this.$route.query?.stock || this.$route.query?.stock === 'instock',
        withinYearItem: this.$route.query.wiy === 'true',
        firstItemGroup: {ogpImageUrl: ''},
      }
    },
    computed: {
      itemTagId() {
        return this.giftPageBody.itemTagId
      },
      tagCode() {
        return this.giftPageBody?.itemTag?.code
      },
      stockFilter() {
        return this.giftPageBody.itemTagDisplayConditionStatus === 'show_tag_now_on_sale_item' ? 'instock' : 'outstock'
      },
      perPage() {
        let maxCount = -1
        if (this.giftPageBody.itemTagDisplayMaxCount) maxCount = this.giftPageBody.itemTagDisplayMaxCount
        return maxCount
      },
      pageInfo() {
        return {
          currentPage: 1,
          totalCount: 0,
          perPage: this.perPage,
          orderBy: 'recommend_tag'
        }
      },
      showSectionTag() {
        return this.giftPageBody.itemTag && this.giftPageBody.itemTag.publishedStatus === 'published'
      },

    },
    async mounted() {
      if (this.showSectionTag) {
        await this.fetchItemGroups()
      }
    },
    methods: {
      async fetchItemGroups() {
        const { data } = await this.$apollo
            .query({
              query: GetItemGroupsForApi,
              variables: {
                tagId: this.itemTagId,
                stockFilter: this.stockFilter,
                ...this.pageInfo
              },
              client: 'apiClient'
            })
            .catch(error => {
              this.loadingStop()
              return;
            });
        this.itemGroups = data.itemGroups.items;
        // this.pageInfo = data.itemGroups.pageInfo
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .section-recommend {

  }
</style>
