<template lang="pug">
  .container
    .row
      .col-xs-12
        h1 クレジットカード
        Alert(v-if="errors.length > 0" valiant="danger")
          ul
            li(v-for="error of errors") {{error.message}}

        //Alert(v-if="cardDataList.length > 0" valiant="warning")
        Alert(v-if="cardTotalCount === 0 || cardTotalCount >= 10"  valiant="warning")
          p(v-if="cardTotalCount === 0") クレジットカードは登録されていません。
          p(v-if="cardTotalCount >= 10") クレジットカードの登録は最大10枚までです。

        table.table(v-if="cardDataList.length > 0")
          thead
            tr
              th カード
              th 名前
              th 有効期限(月/年)
              th カード削除
          tbody
            tr(v-for="card of cardDataList")
              td **** **** **** {{card.last4}}
              td {{card.name}}
              td {{card.expMonth}} / {{card.expYear}}
              td
                .back_btn_inline(v-if="payjpCustomer.usingPayJpCardIds.indexOf(card.id) > -1")
                  i.fa.fa-ban.fa-2x
                a.back_btn_inline(v-else)
                  i.fa.fa-trash.fa-2x(@click="openCreditDelModal(card)")
        div(ref="payjpArea")

    Modal(ref="creditDelModal")
      template(v-slot:header)
        h2 クレジットカードの削除
      template(v-slot:body)
        p クレジットカード( **** **** **** {{delCreditCard.last4}} )を削除しますか?
      template(v-slot:footer)
        button.button.btn-sm.pmd-btn-outline.pmd-ripple-effect.btn-default(@click="$refs.creditDelModal.close()") キャンセル
        button.button.btn-sm.pmd-btn-outline.pmd-ripple-effect.btn-danger(@click="deleteCreditCard") 削除する

</template>

<script>
  import GlobalMixin from "../../mixin/GlobalMixin";
  import {CreatePayjpCard, PayjpCustomer, DeletePayjpCard} from "../../graphqls/Payjp";
  import { config } from '../../config/config'

  import Alert from "../../components/alert/Alert";
  import Modal from "../../components/modal/Modal";

  export default {
    name: "mypage-credit",
    mixins: [GlobalMixin],
    components: {
      Modal,
      Alert,
    },
    props: [],
    data() {
      return {
        errors: [],
        cards: [],
        payjpCustomer: {
          email: '',
          cards: {
            data: []
          },
          usingPayJpCardIds: []
        },
        delCreditCard: null
      }
    },
    computed: {
      cardDataList() {
        return this.customer.cards.data
      },
      cardTotalCount() {
        return Number(this.customer.cards.count)
      },
      customer() {
        return this.payjpCustomer
      }
    },
    async mounted() {
      await this.getPayjpCustomer()
      // HTML描画を待って要素をappendしたい
      this.appendPayJpScriptTag();
      // appendPayJpScriptTagでscriptタグをappendとpayjpのscript実行後に処理したい
      window.onTokenCreated = this.onTokenCreated;
      window.onTokenFailed = this.onTokenFailed;
    },
    beforeDestroy () {
      const payjpCheckoutIframeEl = document.getElementById('payjp-checkout-iframe');
      payjpCheckoutIframeEl.parentElement.remove();
      this.$refs.payjpArea.removeChild(this.scriptEl);
      window.PayjpCheckout = null;
      window.onTokenCreated = null;
      window.onTokenFailed = null;
    },
    methods: {
      appendPayJpScriptTag () {
        const attrs = {
          //API公開鍵 情報を入力
          'data-key':  config.pay_jp_public_key,
          'id': 'payjp-script',
          'src': 'https://checkout.pay.jp/prerelease/',
          // 'src': 'https://checkout.pay.jp/',
          'class': 'payjp-button',
          'data-partial': true,//入力後にtokenを作成し、windowを閉じる
          'data-text': '新しいカードを登録',
          'data-submit-text': 'クレジットカード登録',
          'data-lang': 'ja',
          'data-on-created': 'onTokenCreated',
          'data-on-failed': 'onTokenFailed',
          'data-name-placeholder': 'TABERUTO KURASUTO',
          'data-payjp-extra-attribute-email': this.customer.email,
          'data-payjp-three-d-secure': 'true',
          'data-payjp-three-d-secure-workflow': 'subwindow',
        };
        this.scriptEl = document.createElement('script');
        for(let key in attrs) {
          this.scriptEl.setAttribute(key, attrs[key])
        }
        this.$refs.payjpArea.appendChild(this.scriptEl);
      },
      onTokenCreated () {
        let token = document.getElementsByName('payjp-token')[0].value;
        this.createPayjpCard(token)

        // let dataform = new FormData();
        //
        // //undefinedならテキストで undefined のまま送信
        // dataform.append('id',this.$root.user.id);
        // dataform.append('token',token);
        //
        // axios.post('/payjp/tudokakin/', dataform).then(e => {
        //   console.log("成功");
        // }).catch((error) => {
        //   console.log("エラー");
        // });

      },
      onTokenFailed (res) {
        // 処理
      },
      async deleteCreditCard() {
        this.loadingStart();
        const { data } = await this.$apollo
        .mutate({
          mutation: DeletePayjpCard,
          // Parameters
          variables: {
            input: {
              cardId: this.delCreditCard.id
            }
          }
        })
        .catch(error => {
          console.log(error.message)
        });
        const errors = data.deletePayjpCard.errors
        if (errors.length > 0) {
          this.errors = errors
        }
        // this.payjpCustomer = data.payjpCustomer
        this.payjpCustomer = data.deletePayjpCard.customer
        this.loadingStop();
        this.$refs.creditDelModal.close()
      },
      openCreditDelModal(card) {
        this.delCreditCard = card
        this.$refs.creditDelModal.open()
      },
      async getPayjpCustomer() {
        this.loadingStart();
        const { data } = await this.$apollo
        .query({
          query: PayjpCustomer,
        })
        .catch(error => {
          this.loadingStop()
          return;
        });
        this.payjpCustomer = data.payjpCustomer
        this.loadingStop();
      },
      async createPayjpCard(token) {
        this.loadingStart();
        const { data } = await this.$apollo
        .mutate({
          mutation: CreatePayjpCard,
          // Parameters
          variables: {
            input: {
              card: token,
              default: true
            }
          }
        })
        .catch(error => {
          console.log(error.message)
        });
        const errors = data.createPayjpCard.errors
        if (errors.length > 0) {
          this.errors = errors
        }
        this.payjpCustomer = data.createPayjpCard.customer
        this.loadingStop();
        this.resetPayjp()
      },
      resetPayjp() {
        document.getElementsByName('payjp-token')[0].value = ""
        document.querySelector("#payjp_checkout_box input[type='button']").value = '新しいカードを登録'
        document.querySelector("#payjp_checkout_box input[type='button']").classList.remove('has-token');
      }
    }

  }
</script>

<style scoped lang="scss">
</style>
