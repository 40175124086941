<template lang="pug">
  section.section-recommend#recommend
    HeadingGift.mb-sm
      h2 
        span.sub {{ giftPageBody.subTitle }}
        br 
        | {{ giftPageBody.title }}
    ItemCardList
      .item(v-for="(itemGroup, index) in itemGroups" :key="itemGroup.id")
        ItemCard(:itemGroup="itemGroup" eventCategory="スタッフおすすめ" :pageContents="giftPageBody.title" :rank="rankingNo(index)" )
</template>

<script>
  import HeadingGift from '../heading/HeadingGift'
  import ItemCard from '../item/ItemCardForApi'
  import ItemCardList from '../item/ItemCardList'

  export default {
    name: 'SectionRecommend',
    components: {
      HeadingGift,
      ItemCard,
      ItemCardList
    },
    props: {
      giftPageBody: {
        type: Object,
        required: true,
        default: function () {
          return {}
        }
      }
    },
    computed: {
      itemGroups() {
        return this.giftPageBody.giftPageBodyItemGroups.map(giftPageBodyItemGroup => giftPageBodyItemGroup.itemGroup)
      }
    },
    methods: {
      rankingNo(index) {
        return this.giftPageBody.rankingStatus === 'ranking_on' ? index + 1 : 0
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .section-recommend {

  }
</style>
