import gql from 'graphql-tag'

export const GetOrderItemReview = gql`
query($orderItemId: ID!) {
  orderItem(orderItemId: $orderItemId) {
    id
    name
    size
    itemGroup {
      id
      name
      listImageOrItemGroupImageSet {
        smSqImage
      }
    }
    adminShop {
      name
      code
      logoImageSet {
        xsSqImage
      }
    }
    review {
      id
      author
      title
      comment
      stars
      postedAt
      reviewImages {
        id
        imageSet {
          smSqImage
        }
        rowOrder
      }
    }
    order {
      user {
        nickname
      }
    }
  }
}`

export const CreateOrderItemReview = gql`
mutation ($input: CreateOrderItemReviewInput!) {
  createOrderItemReview (input: $input) {
    review {
      id
    }
    errors {
      statusCode
      model
      attribute
      message
      fullMessage
    }
  }
}`
