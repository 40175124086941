<template lang="pug">
  div
    .visual-title.-xs.-white(:style="`background-image: url(${titleBgImage});`")
      .title
        i.h1rem
          LogoYaneSvg
        h1.heading2 {{itemCategory.name}}
        .heading3.curve-bottom {{itemCategory.subTitle}}
    .archives-description(v-if="itemCategory.comment")
      p {{itemCategory.comment}}

    ItemFilterBlock
      DropDown(@change="$_changeOrder" v-model="pageInfo.orderBy" :options="$_orderDropDownOptions")
      DropDown(@change="changeCategory" v-model="categoryFilter" :options="categoryOptions")
      DropDown(@change="$_changeStorageFilter" v-model="storageFilter" :options="$_storageTypeDropDownOptions")
      .stock-checkbok
        FormCheckbox(@change="$_changeStockFilter" v-model="stockOnly") 販売中のみ表示
    ItemCardList
      .item.lg(v-for="(itemGroup, index) in itemGroups" :key="itemGroup.id")
        ItemCard(:itemGroup="itemGroup" eventCategory="商品一覧" pageContents="商品一覧")
    .row
      .col-xs-12(v-if="itemGroups.length === 0")
        .success-notice
          .text-center 商品がありません。
    .col-xs-12
        Pager(@change="$_changePage" :custom-page-info="$_customPageInfo")

    .row.mb4(v-if="!itemCategory.isRoot")
      .col-xs-10.col-xs-push-1.col-sm-4.col-sm-push-4
        ButtonMain(:to="{name: 'category-items-index', params: {categoryCode: itemCategory.root.code}}" event-action="商品一覧へ遷移" event-category="商品一覧子カテゴリ（初期全表示）" :event-label="itemCategory.root.name")
          | {{itemCategory.root.name}}を全て見る

    .row.mt3.mb3(v-if="itemCategory.rootChildCategories.length > 0")
      .col-xs-12
        template(v-for="childCategory in itemCategory.rootChildCategories")
          template(v-if="childCategory.openItemNum > 0")
            .pmd-chip.mr0_5.mb0_5.bg_light(v-if="childCategory.code === categoryCode") {{childCategory.name}} ({{childCategory.openItemNum}})
            router-link.pmd-chip.mr0_5.mb0_5(v-else :to="{name: 'category-items-index', params: {categoryCode: childCategory.code}}") {{childCategory.name}} ({{childCategory.openItemNum}})

    #reviews(v-if="reviewItems.length > 0")
      .item_review_area
        HeadingArticle.mb-xs {{itemCategory.name}} お取り寄せ通販口コミ・レビュー
        .row.review.mt1.mb3(v-for="review in reviewItems" :key="review.id")
          .col-xs-4.col-sm-2
            a(:href="`/shop/${review.adminShop.code}/item/${review.itemGroup.code}/`")
              LazyImg(:src="review.thumbnailReviewImageUrl" :alt="`{{review.itemGroup.name}}の口コミ・レビュー({{review.displayAuthor}}さん)`")
          .col-xs-8.col-sm-10
            .review_comment.mb1
              p
                | {{review.itemGroup.name}}をお取り寄せ / {{review.displayAuthor}}さん
                span(v-if="review.user && review.user.prefecture")
                  | ({{review.user.prefecture}}) / 投稿日: {{review.postedAt | moment('YYYY/MM/DD')}}
              p
                .font_bold.mb0_5 {{review.title}}
                | {{review.comment}}
              a.btn-link.pmd-ripple-effect(:href="`/shop/${review.adminShop.code}/item/${review.itemGroup.code}/`")
                | {{review.itemGroup.name}}を見る
                i.fa.fa-caret-right.ml0_5

    SectionItemArchive

</template>

<script>
import {GetItemGroupsForApi} from "../../graphqls/ItemGroups";
import {GetItemCategory} from "../../graphqls/ItemCategories";
import {GetReviews} from "../../graphqls/Reviews";

import LogoYaneSvg from "../../components/svg/LogoYaneSvg";
import ItemList from "../../components/list/ItemList";
import GlobalMixin from "../../mixin/GlobalMixin";
import Pager from "../../components/paginate/Pager";
import DropDown from "../../components/v1/dropdown/DropDown";
import ItemListMixin from "../../mixin/ItemListMixin";
import ButtonMain from "../../components/button/ButtonMain";
import ReviewItem from "./ReviewItem";
import LazyImg from "../../components/image/LazyImg";
import ItemCardList from "../../components/v1/item/ItemCardList";
import ItemCard from "../../components/v1/item/ItemCardForApi";
import SectionItemArchive from "../../components/v1/section/SectionItemArchive";
import HeadingArticle from "../../components/v1/typography/HeadingArticle";
import FormCheckbox from "../../components/v1/form/FormCheckbox";
import ItemFilterBlock from "../../components/v1/item/ItemFilterBlock";

export default {
  name: "category-items-index",
    mixins: [GlobalMixin, ItemListMixin],
    components: {
      ItemFilterBlock,
      FormCheckbox,
      HeadingArticle,
      SectionItemArchive,
      ItemCard,
      ItemCardList,
      LazyImg,
      ReviewItem,
      ButtonMain,
      DropDown,
      Pager,
      ItemList,
      LogoYaneSvg
    },
    props: [],
    data() {
      return {
        itemCategory: {
          ogImageSet: {
            ogpImage: ''
          },
          rootChildCategories: [],
          isRoot: true
        },
        itemGroups: [],
        reviews: {items: []},
        pageInfo: {
          currentPage:  Number(this.$route.params.page || 1),
          totalCount: 0,
          perPage: 48,
          orderBy: this.$route.query.order || 'popular'
        },
        stockOnly: this.$route.query?.stock === 'instock',
        withinYearItem: this.$route.query.wiy === 'true',
        firstItemGroup: {
          listImageOrItemGroupImageSet: {
            mdImage: ''
          },
        },
        categoryFilter: this.$route.params.categoryCode,
        storageFilter: this.$route.query?.storage || '',
      }
    },
    computed: {
      titleBgImage() {
        return this.categoryOgpImage || this.firstListImageOrItemGroupImage || ''
      },
      categoryOgpImage() {
        return this.itemCategory.ogImageUrl
      },
      firstListImageOrItemGroupImage() {
        return this.firstItemGroup.listImageOrItemGroupImageSet.mdImage
      },
      topRouterName() {
        return 'category-items-index'
      },
      pageRouterName() {
        return 'category-items-page-index'
      },
      categoryCode() {
        return this.$route.params.categoryCode
      },
      itemGroupIds() {
        return this.itemGroups.map(itemGroup => itemGroup.id);
      },
      reviewItems() {
        return this.reviews.items || [];
      },
      categoryOptions() {
        let result = []
        result.push({id: this.itemCategory.root.code, text: `${this.itemCategory.root.name}の全て`})
        for (const category of this.itemCategory.rootChildCategories) {
          if (category.openItemNum > 0) {
            result.push({id: category.code, text: `${category.name} (${category.openItemNum})`})
          }
        }
        return result
      },
      stockFilter() {
        return this.stockOnly ? 'instock' : 'outstock'
      }
    },
    mounted() {
      this.showBanner()
      this.fetchItemCategory()
      this.fetchItemGroups()
    },
    methods: {
      async fetchItemGroups() {
        this.loadingStart();
        const { data } = await this.$apollo
        .query({
          query: GetItemGroupsForApi,
          variables: {
            categoryCode: this.categoryCode,
            stockFilter: this.stockFilter,
            storageFilter: this.storageFilter,
            highPriceFilter: this.$route.query.hp,
            lowPriceFilter: this.$route.query.lp,
            withinYearItem: this.withinYearItem,
            ...this.pageInfo
          },
          client: 'apiClient'
        })
        .catch(error => {
          this.loadingStop()
          return;
        });
        this.itemGroups = data.itemGroups.items;
        this.firstItemGroup = this.itemGroups[0];
        this.pageInfo = data.itemGroups.pageInfo
        this.$scrollTo("body")
        this.loadingStop();
        if (this.itemGroups.length > 0) this.fetchReviews()
        this.$_gaSendViewItemList(this.itemGroups, `shops/items/category/${this.$route.params.categoryCode}`, this.pageInfo)
      },
      async fetchItemCategory() {
        const { data } = await this.$apollo
        .query({
          query: GetItemCategory,
          variables: {
            itemCategoryCode: this.categoryCode,
          }
        })
        .catch(error => {
          return;
        });
        this.itemCategory = data.itemCategory;
      },
      async fetchReviews() {
        const { data } = await this.$apollo
        .query({
          query: GetReviews,
          variables: {
            perPage: 10,
            search: JSON.stringify({item_group_id_in: this.itemGroupIds}),
            orderBy: 'reviews.posted_at desc'
          },
          client: 'apiClient'
        })
        .catch(error => {
          return;
        });
        this.reviews = data.reviews;
      },
      updateItemGroup(newItemGroup) {
        if (this.itemGroups[0].id === newItemGroup.id) {
          this.firstItemGroup = newItemGroup
        }
      },
      changeCategory(code) {
        this.categoryFilter = code
        this.$router.push({name: 'category-items-index', params: {categoryCode: code}})
      }
    },
    watch: {
      categoryCode: function (newQuestion, oldQuestion) {
        this.stockFilter = 'instock'
        this.pageInfo.orderBy = 'popular'
        this.pageInfo.currentPage = 1
        this.fetchItemCategory()
        this.fetchItemGroups()
      }
    }
  }
</script>

<style scoped lang="scss">
</style>
