<template lang="pug">
  .container(v-if="hasAdminShop")
    //- メインビジュアル
    .mainvisual_shop
      img(:src="titleBgImage" :alt="`${adminShop.name}メイン画像`")

    //- ショップ紹介
    ShopInfo(:adminShop="adminShop")

    //- 画像カルーセル
    SectionShopSlider(v-if="adminShop.code" :adminShop="adminShop")

    //- おすすめ商品
    HeadingArticle.mb-xs {{ adminShop.name }}のおすすめ商品
    ItemCardList
      .item.lg(v-for="(itemGroup, index) in itemGroups" :key="itemGroup.id")
        ItemCard(:itemGroup="itemGroup" eventCategory="商品一覧" pageContents="おすすめ商品")
    .row
      .col-xs-12(v-if="itemGroups.length === 0")
        .success-notice
          .text-center 商品がありません。
    .row.mb4
      .col-xs-10.col-xs-push-1.col-sm-4.col-sm-push-4
        ButtonBase(:to="{name: 'shops-items-index', params: {adminShopCode: adminShopCode}}" event-action="商品一覧へ遷移" event-category="ショップ商品一覧" :event-label="adminShop.name")
          | 全ての商品を見る

    //- 人気商品
    SectionItemRanking(v-if="adminShop.code" :adminShop="adminShop")

    //- 生産者のお便りとお知らせ
    SectionEachShopPost(v-if="adminShop.code" :adminShop="adminShop")

    //- 新着レシピ
    SectionShopRecipe(v-if="adminShop.code" :adminShop="adminShop")

</template>

<script>
  import {GetAdminShop} from "../../graphqls/AdminShops";
  import { GetItemGroupsForApi } from "../../graphqls/ItemGroups";

  import LogoYaneSvg from "../../components/svg/LogoYaneSvg";
  import ItemList from "../../components/list/ItemList";
  import GlobalMixin from "../../mixin/GlobalMixin";
  import Pager from "../../components/paginate/Pager";
  import DropDown from "../../components/v1/dropdown/DropDown";
  import ItemListMixin from "../../mixin/ItemListMixin";
  import ButtonMain from "../../components/button/ButtonMain";
  import ButtonBase from "../../components/button/ButtonBase";
  import LazyImg from "../../components/image/LazyImg";
  import SectionItemArchive from "../../components/v1/section/SectionItemArchive";
  import ItemFilterBlock from "../../components/v1/item/ItemFilterBlock";
  import FormCheckbox from "../../components/v1/form/FormCheckbox";
  import ItemCardList from "../../components/v1/item/ItemCardList";
  import ItemCard from "../../components/v1/item/ItemCardForApi";
  import SectionItemRanking from "../../components/v1/section/SectionItemRanking";
  import HeadingArticle from "../../components/v1/typography/HeadingArticle";
  import ShopInfo from "../../components/v1/shop/ShopInfo";
  import SectionEachShopPost from "../../components/v1/section/SectionEachShopPost";
  import SectionShopRecipe from "../../components/v1/section/SectionShopRecipe";
  import SectionShopSlider from "../../components/v1/section/SectionShopSlider";

  export default {
    name: "shops-show",
    mixins: [GlobalMixin, ItemListMixin],
    components: {
      SectionItemRanking,
      ItemCard,
      ItemCardList,
      FormCheckbox,
      ItemFilterBlock,
      SectionItemArchive,
      LazyImg,
      // ReviewItem,
      ButtonMain,
      ButtonBase,
      DropDown,
      Pager,
      ItemList,
      LogoYaneSvg,
      HeadingArticle,
      ShopInfo,
      SectionEachShopPost,
      SectionShopRecipe,
      SectionShopSlider,
    },
    props: [],
    data() {
      return {
        adminShop: {
          name: "",
          ogImageSet: {
            lgImage: ''
          },
        },
        itemGroups: [],
        pageInfo: {
          currentPage:  Number(this.$route.params.page || 1),
          totalCount: 0,
          perPage: 12,
          orderBy: this.$route.query.order || 'recommend_shop'
        },
        stockOnly: this.$route.query?.stock === 'instock',
      }
    },
    computed: {
      hasAdminShop() {
        return !!this.adminShop.name
      },
      titleBgImage() {
        return this.ogImage || ''
      },
      ogImage() {
        return this.adminShop.ogImageSet.lgImage
      },
      adminShopCode() {
        return this.$route.params.adminShopCode
      },
      stockFilter() {
        return this.stockOnly ? 'instock' : 'outstock'
      }
    },
    mounted() {
      this.fetchAdminShop()
      this.fetchItemGroups()
    },
    methods: {
      toggleReadMore() {
        this.isReadMore = !this.isReadMore;
      },
      async fetchItemGroups() {
        this.loadingStart();
        const { data } = await this.$apollo
          .query({
            query: GetItemGroupsForApi,
            variables: {
              adminShopCode: this.adminShopCode,
              stockFilter: this.stockFilter,
              ...this.pageInfo
            },
            client: 'apiClient'
          })
          .catch(error => {
            this.loadingStop()
            return;
          });
        this.itemGroups = data.itemGroups.items;
        this.pageInfo = data.itemGroups.pageInfo
        this.$_gaSendViewItemList(this.itemGroups, `shop/${this.$route.params.tagCode}/items/`, this.pageInfo)
        this.loadingStop();
      },
      async fetchAdminShop() {
        const { data } = await this.$apollo
        .query({
          query: GetAdminShop,
          variables: {
            adminShopCode: this.adminShopCode,
          }
        })
        .catch(error => {
          return;
        });
        this.adminShop = data.adminShop;
      },
    }
  }
</script>

<style scoped lang="scss">
// @import "../../../../assets/stylesheets/v1/styles";
.container {
  width: 100%;
  h1 {
    font-size: 18px;
    margin-bottom: 24px;
  }
  > .mainvisual_shop {
    width: 100%;
    padding-top: 60%; /* 幅に対する高さを60%にする */
    position: relative;
    z-index: 0;
    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>