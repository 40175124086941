<template lang="pug">
  ModalBase(ref="modalBase" size="medium")
    template(slot="header") お届け設定
    template(slot="body")
      FormItem 
        template(slot="title")
          FormLabel(type="required") 配送頻度
        template(slot="input")
          FormPulldown(v-model="form.interval" :options="intervalOptions" :allowClear="false")
      FormItem 
        template(slot="title")
          FormLabel(type="required") 次回お届け予定日
        template(slot="input")
          FormPulldown(v-model="form.deliveryOn" :options="nextDeliveryOnOptions" :allowClear="false")
      FormItem 
        template(slot="title")
          FormLabel 配送希望時間
        template(slot="input")
          FormPulldown(v-model="form.deliveryTime" :options="deliveryTimeOptions" :allowClear="true")
      FormItem 
        template(slot="title")
          FormLabel 備考
        template(slot="input")
          FormTextarea(
            v-model="form.deliveryMemo"
            placeholder="ご要望等"
          )
    template(slot="footer")
      ButtonBase(@click="close()" variant="normal" size="inline") キャンセル
      ButtonBase(@click="updateSubscriptionOrder()" variant="primary") 更新
</template>

<script>
  import GlobalMixin from '../../../mixin/GlobalMixin'
  import ModalBase from '../modal/ModalBase'
  import ButtonBase from '../button/ButtonBase'
  import FormItem from '../form/FormItem'
  import FormLabel from '../form/FormLabel'
  import FormTextarea from '../form/FormTextarea'
  import FormPulldown from '../../form/FormPulldown.vue'
  import {
    GetSubscriptionOrderSelectOptions,
  } from "../../../graphqls/SubscriptionOrderSummaries";
  import {UpdateSubscriptionOrder} from "../../../graphqls/SubscriptionOrder";

  export default {
    name: 'ModalSubscriptionDelivery',
    mixins: [GlobalMixin],
    components: {
      ModalBase,
      ButtonBase,
      FormItem,
      FormLabel,
      FormTextarea,
      FormPulldown,
    },
    data() {
      return {
        form: {
          id: '',
          interval: '',
          deliveryOn: '',
          deliveryTime: '',
          deliveryMemo: '',
        },
        selectOptions: {
          subscriptionOrderIntervalOptions: [],
          orderDeliveryTimeOptions: [],
        }
      }
    },
    props: {
      subscriptionOrder: {
        type: Object,
        default () {
          return {
            id: null,
            deliveryOnOptions: [],
            deliveryOn: "",
            deliveryTime: "",
            deliveryMemo: "",
            item: {
              subscriptionItemIntervals: [
                {
                  intervalType: "",
                  intervalName: "",
                  intervalDays: "",
                }
              ]
            }
          }
        }
      },
    },
    watch: {
      subscriptionOrder(newVal) {
        for (let key in this.form) {
          this.form[key] = newVal[key] || "";
        }
      }
    },
    computed: {
      intervalOptions() {
        let result = []
        const sortedList = this.subscriptionOrder.item.subscriptionItemIntervals.sort((a, b) => {
          return Number(a.intervalDays) - Number(b.intervalDays);
        });
        for (const interval of sortedList) {
          result.push({
            id: interval.intervalType,
            text: interval.intervalName
          })
        }
        return result
      },
      nextDeliveryOnOptions() {
        return this.subscriptionOrder.deliveryOnOptions
      },
      deliveryTimeOptions() {
        return this.selectOptions.orderDeliveryTimeOptions
      },
    },
    mounted() {
      this.getSelectOption()
      this.form.deliveryOn = this.deliveryOn
    },
    methods: {
      async getSelectOption() {
        this.loadingStart();
        const { data } = await this.$apollo
            .query({
              query: GetSubscriptionOrderSelectOptions,
              client: 'apiClient'
            })
            .catch(error => {
              this.loadingStop()
              return;
            });
        this.selectOptions = data.selectOptions
        this.loadingStop();
      },
      open() {
        this.$refs.modalBase.open()
        this.$emit('open')
      },
      close() {
        this.$refs.modalBase.close()
        this.$emit('close')
      },
      async updateSubscriptionOrder() {
        this.loadingStart();
        console.log('{ ...this.form }', { ...this.form })
        const { data } = await this.$apollo
            .mutate({
              mutation: UpdateSubscriptionOrder,
              // Parameters
              variables: {
                input: { ...this.form }
              },
              client: 'apiClient'
            })
            .catch(error => {
              console.log(error.message)
            });
        const errors = data.updateSubscriptionOrder.errors
        if (!errors) {
          this.close()
          this.$emit('update', data.updateSubscriptionOrder)
        } else {
          this.errors = errors
        }
        this.loadingStop();
      },

    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .modal-base {
    .body {
      section {
        .heading {
          font-family: $font-family-sans;
          font-weight: $font-weight-bold;
          font-style: $font-style-not-italic;
          @include font-size(sm);
          line-height: $line-height-snug;
          color: $black-400;
          @include margin-bottom(md);
        }

        .info {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;

          > .quantity {
            @include margin-right(sm);
          }
          > .price {
            font-weight: 700;
          }
          > .unit {
            @include font-size(xxs)
          }
        }
      }
    }
  }
</style>
