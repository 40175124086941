<template lang="pug">
  div
    .visual-title.-xs.-white(:style="`background-image: url(${titleBgImage});`")
      .title
        i.h1rem
          LogoYaneSvg
        h1.heading2 {{itemTag.name}}
        .heading3.curve-bottom {{itemTag.subTitle}}
    .archives-description(v-if="itemTag.description")
      p {{itemTag.description}}


    ItemFilterBlock
      DropDown(@change="$_changeOrder" v-model="pageInfo.orderBy" :options="$_orderDropDownForTagOptions")
      DropDown(@change="$_changeStorageFilter" v-model="storageFilter" :options="$_storageTypeDropDownOptions")

      .stock-checkbok
        FormCheckbox(@change="$_changeStockFilter" v-model="stockOnly") 販売中のみ表示

    ItemCardList
      .item.lg(v-for="(itemGroup, index) in itemGroups" :key="itemGroup.id")
        ItemCard(:itemGroup="itemGroup" :eventCategory="`${itemTag.name}タグ商品一覧`" :pageContents="`${itemTag.name}タグ商品一覧`")
    .row
      .col-xs-12(v-if="itemGroups.length === 0")
        .success-notice
          .text-center 商品がありません。

      .col-xs-12
          Pager(@change="$_changePage" :custom-page-info="$_customPageInfo")

    .row.back-button(v-if="backButtonLink && backButtonLabel" )
      .col-xs-12
        .text-center
          ButtonBase(:href="backButtonLink" :eventAction="backButtonAction" :eventCategory="backButtonCategory") {{backButtonLabel}}


    .reviews(v-if="reviewItems.length > 0")
      .item_review_area
        HeadingArticle.mb-xs {{itemTag.name}} お取り寄せ通販口コミ・レビュー
        .row.review.mt1.mb3(v-for="review in reviewItems" :key="review.id")
          .col-xs-4.col-sm-2
            a(:href="`/shop/${review.adminShop.code}/item/${review.itemGroup.code}/`")
              LazyImg(:src="review.thumbnailReviewImageUrl" :alt="`{{review.itemGroup.name}}の口コミ・レビュー({{review.displayAuthor}}さん)`")
          .col-xs-8.col-sm-10
            .review_comment.mb1
              p
                | {{review.itemGroup.name}}をお取り寄せ / {{review.displayAuthor}}さん
                span(v-if="review.user && review.user.prefecture")
                  | ({{review.user.prefecture}}) / 投稿日: {{review.postedAt | moment('YYYY/MM/DD')}}
              p
                .font_bold.mb0_5 {{review.title}}
                | {{review.comment}}
              a.btn-link.pmd-ripple-effect(:href="`/shop/${review.adminShop.code}/item/${review.itemGroup.code}/`")
                | {{review.itemGroup.name}}を見る
                i.fa.fa-caret-right.ml0_5

    SectionItemArchive
</template>

<script>
import {GetItemGroupsForApi} from "../../graphqls/ItemGroups";
import {GetItemTag} from "../../graphqls/ItemTags";
import {GetReviews} from "../../graphqls/Reviews";

import LogoYaneSvg from "../../components/svg/LogoYaneSvg";
import ItemList from "../../components/list/ItemList";
import GlobalMixin from "../../mixin/GlobalMixin";
import Pager from "../../components/paginate/Pager";
import DropDown from "../../components/v1/dropdown/DropDown";
import ItemListMixin from "../../mixin/ItemListMixin";
import ButtonMain from "../../components/button/ButtonMain";
import ReviewItem from "./ReviewItem";
import LazyImg from "../../components/image/LazyImg";
import ItemCardList from "../../components/v1/item/ItemCardList";
import ItemCard from "../../components/v1/item/ItemCardForApi";
import HeadingArticle from "../../components/v1/typography/HeadingArticle";
import SectionItemArchive from "../../components/v1/section/SectionItemArchive";
import ItemFilterBlock from "../../components/v1/item/ItemFilterBlock";
import FormCheckbox from "../../components/v1/form/FormCheckbox";
import ButtonBase from "../../components/v1/button/ButtonBase";

export default {
  name: "tag-items-index",
    mixins: [GlobalMixin, ItemListMixin],
    components: {
      ButtonBase,
      FormCheckbox,
      ItemFilterBlock,
      SectionItemArchive,
      HeadingArticle,
      ItemCard,
      ItemCardList,
      LazyImg,
      ReviewItem,
      ButtonMain,
      DropDown,
      Pager,
      ItemList,
      LogoYaneSvg
    },
    props: [],
    data() {
      return {
        itemTag: {
          name: "",
          ogImageSet: {
            ogpImage: ''
          }
        },
        itemGroups: [],
        reviews: {items: []},
        pageInfo: {
          currentPage:  Number(this.$route.params.page || 1),
          totalCount: 0,
          perPage: 48,
          orderBy: this.$route.query.order || 'recommend_tag'
        },
        stockOnly: !this.$route.query?.stock || this.$route.query?.stock === 'instock',
        withinYearItem: this.$route.query.wiy === 'true',
        storageFilter: this.$route.query?.storage || '',
        firstItemGroup: {
          listImageOrItemGroupImageSet: {
            mdImage: ''
          },
        },
        backButtonLink: this.$route.query?.bbLink || "",
        backButtonLabel: this.$route.query?.bbLabel || "",
        backButtonAction: this.$route.query?.bbAction || "",
        backButtonCategory: this.$route.query?.bbCategory || "",
      }
    },
    computed: {
      titleBgImage() {
        return this.tagMainImage || this.firstListImageOrItemGroupImage || ''
      },
      tagMainImage() {
        return this.itemTag.mainImageUrl
      },
      firstListImageOrItemGroupImage() {
        return this.firstItemGroup.listImageOrItemGroupImageSet.mdImage
      },
      topRouterName() {
        return 'tag-items-index'
      },
      pageRouterName() {
        return 'tag-items-page-index'
      },
      tagCode() {
        return this.$route.params.tagCode
      },
      itemGroupIds() {
        return this.itemGroups.map(itemGroup => itemGroup.id);
      },
      reviewItems() {
        return this.reviews.items || [];
      },
      stockFilter() {
        return this.stockOnly ? 'instock' : 'outstock'
      }
    },
    mounted() {
      this.fetchItemTag()
      this.fetchItemGroups()
    },
    methods: {
      async fetchItemGroups() {
        this.loadingStart();
        const { data } = await this.$apollo
        .query({
          query: GetItemGroupsForApi,
          variables: {
            tagCode: this.tagCode,
            stockFilter: this.stockFilter,
            storageFilter: this.storageFilter,
            highPriceFilter: this.$route.query.hp,
            lowPriceFilter: this.$route.query.lp,
            withinYearItem: this.withinYearItem,
            ...this.pageInfo
          },
          client: 'apiClient'
        })
        .catch(error => {
          this.loadingStop()
          return;
        });
        this.itemGroups = data.itemGroups.items;
        this.firstItemGroup = this.itemGroups[0];
        this.pageInfo = data.itemGroups.pageInfo
        if (this.itemGroups.length > 0) this.fetchReviews()
        this.$_gaSendViewItemList(this.itemGroups, `shops/items/tag/${this.$route.params.tagCode}`, this.pageInfo)
        this.$scrollTo("body")
        this.loadingStop();
      },
      async fetchItemTag() {
        const { data } = await this.$apollo
        .query({
          query: GetItemTag,
          variables: {
            itemTagCode: this.tagCode,
          }
        })
        .catch(error => {
          return;
        });
        this.itemTag = data.itemTag;
      },
      async fetchReviews() {
        const { data } = await this.$apollo
        .query({
          query: GetReviews,
          variables: {
            perPage: 10,
            search: JSON.stringify({item_group_id_in: this.itemGroupIds}),
            orderBy: 'reviews.posted_at desc'
          },
          client: 'apiClient'
        })
        .catch(error => {
          return;
        });
        this.reviews = data.reviews;
      },
      updateItemGroup(newItemGroup) {
        if (this.itemGroups[0].id === newItemGroup.id) {
          this.firstItemGroup = newItemGroup
        }
      }
    }
  }
</script>

<style scoped lang="scss">
// @import "../../../../assets/stylesheets/v1/styles";
.back-button {
  margin-top: 32px;
  @media (max-width: 768px) {
    margin-top: 24px;
  }
}
.reviews {
  margin-top: 32px;
  @media (max-width: 768px) {
    margin-top: 24px;
  }
}
</style>
